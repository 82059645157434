import { render, staticRenderFns } from "./zip.vue?vue&type=template&id=620fca86&scoped=true"
import script from "./zip.vue?vue&type=script&lang=js"
export * from "./zip.vue?vue&type=script&lang=js"
import style0 from "./zip.vue?vue&type=style&index=0&id=620fca86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620fca86",
  null
  
)

export default component.exports